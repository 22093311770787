import React from 'react'
import styled from 'styled-components'
import Box from 'Atoms/Box'

const Svg = styled.svg`
  width: 50px;
  margin: 0;
  fill: ${({theme}) => theme.colors.gold};
  transition: fill 400ms ease-in-out;
  &:hover {fill: ${({theme}) => theme.colors.white}}
`

const Contact = () => (
  <Box display='flex' alignItems='center' justifyContent='center'>
    <a href="mailto:thebornreadies@gmail.com?Subject=Howdy%20Doody" target="_top">
      <Svg viewBox="-20 -20 600 600">
        <path id="mail" d="M515.6,34.4H33.9l0.5,480.7l481.2,0.5V34.4z M414.2,174.3l-140,113.5l-140-113.5
          C134.2,174.3,414.2,174.3,414.2,174.3z M414.4,374.5H134V204.2l140.2,113.6l140.2-113.6V374.5z"/>
      </Svg>
    </a>

    <a href="https://www.facebook.com/TheBornReadies/" target="_blank">
      <Svg viewBox="-255 129 512 512">
      <path id="facebook" d="M-204.5,179v412h412V179H-204.5z M69.2,289.7c0,0-22.9,0-32,0
        c-11.3,0-13.6,4.6-13.6,16.3c0,9.7,0,28.2,0,28.2h45.6l-4.4,49.6H23.6v148h-59.1V384.4h-30.7v-50.2h30.7c0,0,0-7.2,0-39.5
        c0-37.1,19.8-56.5,63.8-56.5c7.2,0,40.9,0,40.9,0V289.7z"/>
    </Svg>
    </a>

    <a href="https://www.instagram.com/thebornreadies/" target="_blank">
      <Svg viewBox="-255 129 512 512">
        <path id="instagram" d="M112.6,279.2V309c0,4.8-3.9,8.6-8.6,8.6H74.9c-4.8,0-8.6-3.9-8.6-8.6v-29.8
          c0-4.8,3.9-8.6,8.6-8.6H104C108.8,270.6,112.6,274.4,112.6,279.2z M1,426c21.9,0,39.7-17.8,39.7-39.7c0-21.9-17.8-39.7-39.7-39.7
          c-21.9,0-39.7,17.8-39.7,39.7C-38.7,408.2-20.9,426,1,426z M207,179v412h-412V179H207z M137.3,345.9H57.7
          c8.1,11.4,12.9,25.3,12.9,40.4c0,38.5-31.2,69.7-69.7,69.7c-38.5,0-69.7-31.2-69.7-69.7c0-15.1,4.8-29,12.9-40.4h-79.3v122.2
          c0,29.8,24.1,53.9,53.9,53.9H83.4c29.8,0,53.9-24.1,53.9-53.9L137.3,345.9L137.3,345.9z M0.8,440c29.6,0,53.7-24.1,53.7-53.7
          c0-16.1-7.1-30.5-18.3-40.4c-3.9-3.5-8.4-6.3-13.2-8.5c-6.8-3.1-14.3-4.8-22.2-4.8s-15.4,1.7-22.2,4.8c-4.8,2.2-9.3,5.1-13.2,8.5
          c-11.2,9.9-18.4,24.3-18.4,40.4C-52.9,415.9-28.8,440,0.8,440z M137.3,301.9c0-29.8-24.1-53.9-53.9-53.9H-63.9v68H-75v-68h-6.4
          c-0.4,0-0.8,0-1.2,0V316h-11.1v-66.5c-2.7,0.6-5.3,1.4-7.8,2.5V316h-11.1v-58c-13.8,9.8-22.8,25.8-22.8,44v35.4h86.4
          c12.6-12.8,30.2-20.8,49.7-20.8c19.4,0,37,8,49.7,20.8h86.8L137.3,301.9L137.3,301.9z"/>
      </Svg>
    </a>

    <a href="https://thebornreadies.bandcamp.com/" target="_blank">
    <Svg viewBox="-20 -20 600 600">
      <path id="bandcamp" d="M290.1,208.8v0.2l0.1-0.2H290.1z M335.9,259.5c-17.2,0-26.1,13.6-26.1,34c0,19.3,9.5,33.8,26.1,33.8
        c18.7,0,25.7-17.1,25.7-33.8C361.6,276,352.8,259.5,335.9,259.5z M335.9,259.5c-17.2,0-26.1,13.6-26.1,34c0,19.3,9.5,33.8,26.1,33.8
        c18.7,0,25.7-17.1,25.7-33.8C361.6,276,352.8,259.5,335.9,259.5z M290.1,209l0.1-0.2h-0.1V209z M34.4,34.4v481.2h481.2V34.4H34.4z
         M342.1,344.1c-12.6,0-26.1-3.2-32.2-15.8h-0.4v13.2h-19.4V209l-71.6,132.2H64.9l71.7-132.4h153.5v0h20.5v49.1h0.4
        c5.6-9.3,17.3-15.1,27.7-15.1c29.3,0,43.5,23.1,43.5,51.1C382.1,319.7,369.6,344.1,342.1,344.1z M435.6,327.3
        c11.9,0,20.1-8.2,22.1-21.9h20.5c-3.8,24.9-18.7,38.7-42.6,38.7c-29.1,0-45.1-21.4-45.1-49.6c0-29,15.3-51.7,45.8-51.7
        c21.6,0,39.9,11.2,41.9,34.8h-20.5c-1.6-11.7-9.9-18-21.2-18c-10.6,0-25.5,5.8-25.5,34.9C411,310.3,417.8,327.3,435.6,327.3z
         M335.9,259.5c-17.2,0-26.1,13.6-26.1,34c0,19.3,9.5,33.8,26.1,33.8c18.7,0,25.7-17.1,25.7-33.8C361.6,276,352.8,259.5,335.9,259.5z
         M290.1,208.8v0.2l0.1-0.2H290.1z"/>
     </Svg>
    </a>

    <a href="https://www.youtube.com/channel/UCCTxsp0S8gW1lPlx01RwprQ" target="_blank">
      <Svg
        viewBox="0 0 24 24"
        style={{
          width: '41px',
          height: '50px',
          marginLeft: '3px',
        }}
      >
        <path d="M10 9.333l5.333 2.662-5.333 2.672v-5.334zm14-9.333v24h-24v-24h24zm-4 12c-.02-4.123-.323-5.7-2.923-5.877-2.403-.164-7.754-.163-10.153 0-2.598.177-2.904 1.747-2.924 5.877.02 4.123.323 5.7 2.923 5.877 2.399.163 7.75.164 10.153 0 2.598-.177 2.904-1.747 2.924-5.877z"/></Svg>
    </a>
  </Box>
)

export default Contact
